import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { KibField } from '@chewy/kib-fields-new-react'
import { BillingAddressForm as PaymentsAddressForm } from '@chewy-payments-experience/credit-debit-card-form'
import makeStyles from '@mui/styles/makeStyles'
import { useFields } from '@pbt/pbt-ui-components'
import { getAvailableCountry } from '@pbt/pbt-ui-components/src/utils/regionUtils'

import Features from '../../../constants/features'
import { getCurrentBusiness } from '../../../store/duck/businesses'
import { getCurrentClient } from '../../../store/duck/clients'
import { getFeatureToggle } from '../../../store/duck/constants'
import {
  getMembershipBillingAddress,
  getMembershipBillingAddresses,
  updateMembershipBillingAddress,
} from '../../../store/duck/wellnessPlans'
import { useNavigateWithQueryString } from '../../../utils'
import BillingAddressScreen from '../common/BillingAddressScreen'
import KioskScreen from '../KioskScreen'

import '@chewy/kib-fields-new-styles/dist/styles/kib-field.css'

const useStyles = makeStyles(
  (theme) => ({
    title: {
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 1),
      },
    },
    content: {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 2, 3),
      },
    },
    billingAddress: {
      alignSelf: 'flex-start',
      width: '60%',
      padding: theme.spacing(3, 4),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    nameRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    nameCell: {
      width: 'calc(50%) !important',
      '&:first-of-type': {
        marginRight: theme.spacing(2),
      },
    },
  }),
  { name: 'MembershipBillingAddressScreen' },
)

const MembershipBillingAddressScreen = () => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const navigateWithQueryString = useNavigateWithQueryString()
  const dispatch = useDispatch()
  const client = useSelector(getCurrentClient)
  const business = useSelector(getCurrentBusiness)

  const billingAddresses = useSelector(getMembershipBillingAddresses)
  const billingAddress = useSelector(getMembershipBillingAddress) || {}

  const isIpoM2bBraintreeSignupEnabled = useSelector(
    getFeatureToggle(Features.IPO_M2B_BRAINTREE_SIGNUP),
  )
  const isBusinessOmniChannel = business?.omniChannel

  const shouldUseBraintree =
    isIpoM2bBraintreeSignupEnabled && isBusinessOmniChannel

  const { fields, validate } = useFields(
    [
      {
        name: 'firstName',
        label: t('Common:FIRST_NAME'),
        validators: ['required'],
        initialValue: client?.firstName,
      },
      {
        name: 'lastName',
        label: t('Common:LAST_NAME'),
        validators: ['required'],
        initialValue: client?.lastName,
      },
      {
        name: 'address',
        label: t('Common:STREET_ADDRESS'),
        validators: ['required'],
        initialValue: client?.address ?? '',
      },
      {
        name: 'apartmentNumber',
        label: t('Common:APARTMENT_NUMBER'),
        initialValue: client?.suite,
      },
      {
        name: 'country',
        label: t('Common:COUNTRY'),
        validators: ['required'],
        initialValue: getAvailableCountry(client?.country || business?.country),
      },
      {
        name: 'city',
        label: t('Common:CITY'),
        validators: ['required'],
        initialValue: client?.city,
      },
      {
        name: 'state',
        label: t('Common:STATE'),
        validators: ['required'],
        type: 'select',
        initialValue: client?.state,
      },
      {
        name: 'zip',
        label: t('Common:ZIP_CODE'),
        validators: ['required', 'zip'],
        initialValue: client?.zip,
      },
    ],
    false,
  )

  const {
    firstName,
    lastName,
    address,
    apartmentNumber,
    country,
    city,
    state,
    zip,
  } = fields

  const createAddress = () => ({
    firstName: firstName.value,
    lastName: lastName.value,
    street: address.value,
    apartmentNumber: apartmentNumber.value,
    city: city.value,
    state: state.value,
    zipCode: zip.value,
    country: country.value,
  })

  const billingAddressRef = useRef()

  const onProceed = () => {
    if (shouldUseBraintree) {
      if (validate()) {
        dispatch(updateMembershipBillingAddress(createAddress()))
        navigateWithQueryString({ url: '/membership/payment-method' })
      }
    } else if (billingAddressRef.current.validate()) {
      dispatch(
        updateMembershipBillingAddress(
          billingAddressRef.current.getBillingAddress(),
        ),
      )

      navigateWithQueryString({ url: '/membership/payment-method' })
    }
  }

  const onBeforeBack = () => {
    if (shouldUseBraintree) {
      dispatch(updateMembershipBillingAddress(createAddress()))
    } else {
      dispatch(
        updateMembershipBillingAddress(
          billingAddressRef.current.getBillingAddress(),
        ),
      )
    }
  }

  const handleAddressSuggestion = (addressSuggestion) => {
    if (addressSuggestion?.address1) {
      address.setValue(addressSuggestion.address1)
    }
    if (addressSuggestion?.address2) {
      apartmentNumber.setValue(addressSuggestion.address2)
    }
    if (addressSuggestion?.city) {
      city.setValue(addressSuggestion.city)
    }
    if (addressSuggestion?.state) {
      state.setValue(addressSuggestion.state)
    }
    if (addressSuggestion?.zipCode) {
      zip.setValue(addressSuggestion.zipCode)
    }
  }

  return (
    <KioskScreen
      classes={{
        title: classes.title,
      }}
      justifyContent="flex-start"
      proceedButtonLabel={t('Common:NEXT_ACTION')}
      title={t('Common:BILLING_ADDRESS')}
      onBeforeBack={onBeforeBack}
      onProceed={onProceed}
    >
      {shouldUseBraintree ? (
        <div className={classes.billingAddress}>
          <div className={classes.nameRow}>
            <KibField
              className={classes.nameCell}
              invalid={!firstName.valid}
              label={firstName.label}
              messagesContent={firstName.message}
              value={firstName.value}
              onChange={(event) => firstName.setValue(event.target.value)}
            />
            <KibField
              className={classes.nameCell}
              invalid={!lastName.valid}
              label={lastName.label}
              messagesContent={lastName.message}
              value={lastName.value}
              onChange={(event) => lastName.setValue(event.target.value)}
            />
          </div>
          <PaymentsAddressForm
            address1={{
              name: 'street',
              value: address.value,
              label: address.label,
              invalid: !address.valid,
              onChange: (event) => address.setValue(event.target.value),
              messagesContent: address.message,
            }}
            address2={{
              name: 'apartmentNumber',
              value: apartmentNumber.value,
              onChange: (event) => apartmentNumber.setValue(event.target.value),
              label: apartmentNumber.label,
              invalid: !apartmentNumber.valid,
              messagesContent: apartmentNumber.message,
            }}
            city={{
              name: 'city',
              value: city.value,
              onChange: (event) => city.setValue(event.target.value),
              label: city.label,
              invalid: !city.valid,
              messagesContent: city.message,
            }}
            state={{
              name: 'state',
              value: state.value,
              onChange: (event) => state.setValue(event.target.value),
              label: state.label,
              invalid: !state.valid,
              messagesContent: state.message,
            }}
            zipCode={{
              name: 'zip',
              value: zip.value,
              onChange: (event) => zip.setValue(event.target.value),
              label: zip.label,
              invalid: !zip.valid,
              messagesContent: zip.message,
            }}
            onAddressSuggestion={handleAddressSuggestion}
          />
        </div>
      ) : (
        <BillingAddressScreen
          billingAddresses={billingAddresses}
          className={classes.billingAddress}
          currentBillingAddress={billingAddress}
          formClasses={{
            content: classes.content,
          }}
          ref={billingAddressRef}
        />
      )}
    </KioskScreen>
  )
}

export default MembershipBillingAddressScreen
