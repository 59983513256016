import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { prop } from 'ramda'
import {
  ButtonWithLoader,
  ButtonWithLoaderProps,
  ClassesType,
} from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '../../store/duck/businesses'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      maxWidth: '100%',
      width: 424,
      height: 56,
      fontSize: '1.8rem',
      borderRadius: 28,
    },
    primary: {
      color: (props: UseStylesProps) =>
        props.buttonsColor ? theme.colors.profileText : undefined,
      backgroundColor: prop('buttonsColor'),
      '&&&&:hover': {
        backgroundColor: prop('buttonsColor'),
      },
    },
    secondary: {
      color: (props: UseStylesProps) => props.buttonsColor || '#5798B2',
      backgroundColor: prop('buttonsColor') ? 'transparent' : undefined,
      border: (props: UseStylesProps) =>
        `1px solid ${props.buttonsColor || '#5798B2'}`,
      boxShadow:
        '0 2px 2px 0 rgba(46,153,168,0.14), 0 1px 5px 0 rgba(53,177,194,0.12)',
      '&&&&:hover': {
        backgroundColor: prop('buttonsColor') ? 'transparent' : undefined,
      },
    },
  }),
  { name: 'KioskButtonWithLoader' },
)

interface UseStylesProps {
  buttonsColor: string | undefined
}

interface KioskButtonWithLoaderProps extends ButtonWithLoaderProps {
  readonly className?: string
  readonly classes?: ClassesType<typeof useStyles>
}

const KioskButtonWithLoader = ({
  classes: classesProp,
  className,
  ...rest
}: KioskButtonWithLoaderProps) => {
  const currentBusiness = useSelector(getCurrentBusiness)

  const { customColorsEnabled, buttonsColor } = currentBusiness || {}

  const classes = useStyles({
    buttonsColor: customColorsEnabled ? buttonsColor : undefined,
  })

  return (
    <ButtonWithLoader
      classes={{
        secondary: classes.secondary,
        primary: classes.primary,
        button: classNames(className, classes.button),
        ...classesProp,
      }}
      {...rest}
    />
  )
}

export default KioskButtonWithLoader
